function SplitToChunks(array, parts) {
  const copy = JSON.parse(JSON.stringify(array))
  let result = []
  for (let i = parts; i > 0; i--) {
    result.push(copy.splice(0, Math.ceil(copy.length / i)))
  }
  return result
}

function DividePanelsForHeader() {
  const panels = window.sortByProp(window.panels, 'title')
  const divisions = SplitToChunks(panels, 3)
  return { steps: divisions }
}

function CheckPath(path) {
  const pathname = window.location.pathname
  if (path === '/') return pathname === '/'
  else return pathname.includes(path)
}

function ChangeLocale(locale) {
  localStorage.setItem('locale', locale)
  window.AlpineI18n.locale = locale
}
